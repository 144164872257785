<template>
  <b-dropdown ref="campaigns_dropdown_quick_link" right class="w-full dropdown-menu-right hide_dropdown_caret  default_style_dropdown"
              :no-caret="true">
    <div @click="fetchCampaignOnDropdown" :class="customClass" class="w-full dropdown_header bg-white h-[2.625rem] px-3 py-2 rounded-lg d-flex align-items-center"
         slot="button-content"
         data-cy="select-camp">
      <div v-tooltip="limitTextLength(getSelectedCampaign(),40)" class=" ">
        <div v-if="campaign.name" class="flex items-center justify-start">
          <span class="font-poppins text-[0.75rem] text-[#757A8A]">Campaign</span>
        </div>
        <p class="!text-left !font-poppins !text-[#757A8A] text">{{ limitTextLength(getSelectedCampaign(), 40) }}</p>
      </div>
      <span class="arrow_icon ml-auto"><i class="text-[#757A8A] font-semibold fal fa-angle-down ml-3"></i></span>
    </div>
    <ul id="campaign-dropdown-scroll" @scroll="lazyScroll('campaign-dropdown-scroll','fetchCampaignsList')"
        class="clear inner w-full !px-0 !pb-2 !py-1">
      <template
        v-if="(getCampaignsList && getCampaignsList.length && getCampaignsList.length > 7) || getCampaigns.search">
        <div class="mx-[1.5rem] h-[2.375rem] mb-[1rem] mt-[0.8rem] border !border-[#757A8A] rounded-lg justify-between px-2 items-center flex">
          <input placeholder="Search for campaign"
                 type="text"
                 class="w-full !h-[2.2rem] !border-none px-1"
                 :value="getCampaigns.search"
                 @input="debounceCampaignDropdownSearch"
                 data-cy="search-camp"> <i
          class="fal fa-search"></i>
        </div>
        <hr>
      </template>
      <template v-if="getCampaignsList && getCampaignsList.length">
        <li class="cursor-pointer group hover:bg-[#F4F6FA] px-[1rem] py-[0.75rem] text-[0.875rem] text-[#3C4549] flex justify-between font-poppins items-center" @click="$refs.campaigns_dropdown_quick_link.visible = false"
            v-for="(cta, index) in getComponentCampaignList"
            @click.prevent="$emit('click', cta._id); campaign = cta;  $refs.campaigns_dropdown_quick_link.hide(true)"
            v-if="cta.name" :key="index" data-cy="camps">
          {{ limitTextLength(cta.name, 25) }}
        </li>
        <li v-if="getCampaignLoaders.retrieve" class="list_item_li">
          <clip-loader :color="'#168eea'" :size="'16px'"></clip-loader>
        </li>
      </template>
      <li v-else-if="getCampaignLoaders.retrieve" class="list_item_li">
        <clip-loader :color="'#168eea'" :size="'16px'"></clip-loader>
      </li>
      <li v-if="!getCampaignLoaders.retrieve && !getComponentCampaignList.length && getCampaigns.search.length"
          class="text pt-1 text-center">{{ searchFiltersMessages('').search_field_dropdown }}
      </li>
    </ul>
  </b-dropdown>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
export default {
  props: ['customClass', 'ctaId'],
  computed: {
    ...mapGetters([
      'getCampaigns',
      'getCampaignsList'
    ])
  },
  data () {
    return {
      campaign: {}
    }
  },
  async created () {
    await this.setCampaignWhileEditing()
  },
  methods: {
    ...mapActions(['fetchCampaignsList']),
    /**
     *  getting the selected campaign name
     * @returns {string|*|string}
     */
    getSelectedCampaign () {
      // is selected campaign exists then  returns its name
      return this.campaign && this.campaign.name ? this.campaign.name : 'Select Campaign'
    },
    async setCampaignWhileEditing () {
      this.campaign = this.allCampaign && this.allCampaign.length ? this.allCampaign.find(campaign => campaign._id === this.ctaId) : {}
    }
  }
}
</script>
