<template>
  <div class="py-[0.78rem]">
    <template>
      <div class="flex px-6 w-full justify-between items-center">
        <div class="w-full flex justify-center items-center">
          <div class="mr-40" v-if="getTeamMember.item.length > getTeamMember.limit">
            <TablePagination
              :clickedMethod="paginateTeam"
              :resultCount="getTeamMember.total_items"
              :itemsPerPage="getTeamMember.limit"
              :currentPage="getTeamMember.page"
              :totalPagesNumber="getTeamMember.total_pages"
            ></TablePagination>
          </div>
          <div v-if="getTeamMember.item.length > getTeamMember.limit">
            <TableCustomPage :page="getTeamMember.page" @click="paginateTeam"></TableCustomPage>
          </div>
        </div>
        <PerPageFilter
          :limit="getTeamMember.limit"
          @click="getTeamMember.limit = $event; paginateTeam()"
        ></PerPageFilter>
      </div>
    </template>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
export default {
  components: {
    TablePagination: () => import('@/ui/ui-kit/v2/TablePagination.vue'),
    TableCustomPage: () => import('@/ui/ui-kit/v2/TableCustomPage.vue'),
    PerPageFilter: () => import('@/ui/ui-kit/v2/PerPageFilter.vue'),
  },
  computed: {
    ...mapGetters([
      'getTeamMember'
    ]),
 
  },
  methods: {
    ...mapActions([
      'fetchTeamMembersList'
    ]),
    /* incase team member can be more than 5 then we need to implement it */
    async paginateTeam (page) {
      await this.$store.dispatch('fetchTeamMembersList', page)
    }
  }
}
</script>
