<template>
  <div class="!h-full">
    <RemoveTeamMemberModal></RemoveTeamMemberModal>
    <AddTeamMemberV2 v-if="showAddTeamModal" :selectedUTMsprop="selectedUTMs"></AddTeamMemberV2>
    <div class="w-full !h-full bg-[#F7F7FC]">
      <div
        class="grid_box_shadow bg-white w-full h-full bg-opacity-80 rounded-[8px]"
      >
        <div class="flex flex-col justify-between h-full">
          <div class="flex flex-col flex-grow h-full border-[#ECEEF5]">
            <TeamFilterV2 @updateParentModalStatus="onUpdateParentModalStatus"></TeamFilterV2>
            <TeamTableV2></TeamTableV2>
          </div>
          <!-- <TeamPaginationV2></TeamPaginationV2>  -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import TeamFilterV2 from "./dialogs/TeamFilterV2.vue";
import TeamPaginationV2 from "./dialogs/TeamPaginationV2.vue";
import AddTeamMemberV2 from "./dialogs/AddTeamMemberV2.vue";
import pick from 'lodash.pick'
import { baseURL } from "@/config/api";
import {userTypes} from '@/state/modules/mutation-types'
import {DARK_PURPLE_LOADER_COLOR} from '@/common/constants'



import TeamTableV2 from "./dialogs/TeamTableV2.vue";

export default {
  name: "TeamV2",
  components: {
    TeamPaginationV2,
    TeamFilterV2,
    AddTeamMemberV2,
    TeamTableV2,
    RemoveTeamMemberModal: () => import('@/views/pages/setting/team/dialogs/RemoveTeamMemberModal.vue')
  },
  data
    () {
    return {
      size: '17px',
      color: DARK_PURPLE_LOADER_COLOR,
      showAddTeamModal: false,
      selectedUTMs:[],
    }
  },

  computed: {
    ...mapGetters([
      "getPixelList",
      "getGuidedTour",
      "getTeam",
      "getBrands",
      "getUserLoader",
      "getDomains",
      "getTeamMember",
      "getProfile",
      "getPixel",
    ]),
  },
  async created() {
    this.getSiteDetails;
    if (!this.getTeamMember.fetchStatus) await this.fetchTeamMembersList();
    if (!this.getPixel.fetchStatus) await this.fetchPixels();
    if (!this.getDomains.fetchStatus) await this.fetchDomains();
  },

  methods: {
    ...mapActions([
      "setAllBrandsFetchStatus",
      "fetchPixels",
      "fetchDomains",
      "fetchTeamMembersList",
    ]),
    invitationLink(member) {
      return member.member_token
        ? baseURL + "joinTeam/" + member.member_token
        : "";
    },
    editTeamMemberModal (member) {
      let data = JSON.parse(JSON.stringify(member))
      let selected = pick(data, ['email', 'email', 'role', 'first_name', 'last_name', 'permission'])
      selected.id = data._id
      console.debug(selected)
      this.$store.commit(userTypes.SET_TEAM_ADD_VALUE, selected)
      /* since now we are using the local selceted utms so need to set the array here as well */
      this.selectedUTMs  = selected.permission.utm;
      this.showAddTeamModal = true

      setTimeout(() => {
        this.$bvModal.show('modalAddTeam')
      }, 100)
    },
    onUpdateParentModalStatus(){
      this.showAddTeamModal = true

    },
    closeAddTeamModal () {
      this.$bvModal.hide('modalAddTeam')
      this.showAddTeamModal = false
    }
  },
};
</script>
