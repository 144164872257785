<template>
  <b-modal
    modal-class="modal_basic add-edit-team-modal"
    id="modalAddTeam"
    centered
    hide-footer
    hide-header
  >
    <div class="w-full h-full">
      <div @click="close()" class="float-right w-6 h-6 flex justify-end">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="w-3 h-3 cursor-pointer"
          viewBox="0 0 12 12"
          fill="none"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M10.8161 2.24461C11.109 1.95172 11.109 1.47684 10.8161 1.18395C10.5232 0.891056 10.0483 0.891056 9.75546 1.18395L6.00007 4.93933L2.24469 1.18395C1.95179 0.891056 1.47692 0.891056 1.18403 1.18395C0.891132 1.47684 0.891132 1.95172 1.18403 2.24461L4.93941 5.99999L1.18403 9.75538C0.891132 10.0483 0.891132 10.5231 1.18403 10.816C1.47692 11.1089 1.95179 11.1089 2.24469 10.816L6.00007 7.06065L9.75546 10.816C10.0483 11.1089 10.5232 11.1089 10.8161 10.816C11.109 10.5231 11.109 10.0483 10.8161 9.75538L7.06073 5.99999L10.8161 2.24461Z"
            fill="black"
          />
        </svg>
      </div>
      <div class="w-full h-full px-[1.71rem] flex flex-col items-center justify-between pt-2"
      >
        <div class="flex flex-col items-center justify-center pb-[1.71rem]">
          <svg
            width="40"
            height="32"
            viewBox="0 0 40 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M3.23855 21.4209C4.73033 19.9291 6.75362 19.0911 8.86332 19.0911H21.5907C23.7004 19.0911 25.7237 19.9291 27.2155 21.4209C28.7073 22.9127 29.5454 24.936 29.5454 27.0457V30.2275C29.5454 31.1062 28.8331 31.8185 27.9544 31.8185C27.0758 31.8185 26.3635 31.1062 26.3635 30.2275V27.0457C26.3635 25.7799 25.8607 24.5659 24.9656 23.6708C24.0705 22.7758 22.8566 22.2729 21.5907 22.2729H8.86332C7.5975 22.2729 6.38353 22.7758 5.48846 23.6708C4.59339 24.5659 4.09054 25.7799 4.09054 27.0457V30.2275C4.09054 31.1062 3.37826 31.8185 2.49962 31.8185C1.62097 31.8185 0.908691 31.1062 0.908691 30.2275V27.0457C0.908691 24.936 1.74677 22.9127 3.23855 21.4209Z"
              fill="#2560D7"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M15.2283 3.18185C12.5924 3.18185 10.4555 5.3187 10.4555 7.95463C10.4555 10.5906 12.5924 12.7274 15.2283 12.7274C17.8642 12.7274 20.0011 10.5906 20.0011 7.95463C20.0011 5.3187 17.8642 3.18185 15.2283 3.18185ZM7.27368 7.95463C7.27368 3.56141 10.8351 0 15.2283 0C19.6215 0 23.1829 3.56141 23.1829 7.95463C23.1829 12.3479 19.6215 15.9093 15.2283 15.9093C10.8351 15.9093 7.27368 12.3479 7.27368 7.95463Z"
              fill="#2560D7"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M31.1874 20.4912C31.407 19.6404 32.2748 19.1289 33.1255 19.3485C34.832 19.7891 36.3439 20.784 37.4237 22.177C38.5036 23.57 39.0902 25.2821 39.0915 27.0446L39.0915 30.2277C39.0915 31.1063 38.3792 31.8186 37.5006 31.8186C36.6219 31.8186 35.9096 31.1063 35.9096 30.2277V27.0464C35.9087 25.9891 35.5568 24.9621 34.909 24.1264C34.2611 23.2906 33.354 22.6937 32.3301 22.4293C31.4793 22.2097 30.9677 21.3419 31.1874 20.4912Z"
              fill="#2560D7"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M24.8216 1.40519C25.0395 0.554007 25.9062 0.0406573 26.7574 0.258596C28.4685 0.696699 29.9851 1.69182 31.0681 3.08708C32.1511 4.48234 32.739 6.19838 32.739 7.96464C32.739 9.73091 32.1511 11.4469 31.0681 12.8422C29.9851 14.2375 28.4685 15.2326 26.7574 15.6707C25.9062 15.8886 25.0395 15.3753 24.8216 14.5241C24.6036 13.6729 25.117 12.8062 25.9682 12.5883C26.9948 12.3254 27.9048 11.7283 28.5546 10.8912C29.2044 10.054 29.5571 9.0244 29.5571 7.96464C29.5571 6.90488 29.2044 5.87527 28.5546 5.03811C27.9048 4.20095 26.9948 3.60388 25.9682 3.34101C25.117 3.12308 24.6036 2.25638 24.8216 1.40519Z"
              fill="#2560D7"
            />
          </svg>
          <p
            v-if="getTeam.id"
            class="text-[#3C4549] font-poppins pt-[1.188rem] text-[16px] font-semibold leading-5"
          >
            Edit Team Member
          </p>
          <p
            v-else
            class="text-[#3C4549] font-poppins pt-[1.188rem] text-[16px] font-semibold leading-5"
          >
            Add New Team Member
          </p>

          <p v-if="getTeam.id"
             class="pt-1 text-[14px] text-[#757A8A] font-poppins text-center !font-normal">
            Please enter details to edit team member.
          </p>
          <p
            v-else
            class="pt-1 text-[14px] text-[#757A8A] font-poppins text-center !font-normal">
            Please enter details to add new team member.
          </p>
        </div>

        <div class="flex flex-col justify-center item-center w-full">
          <FloatingLabelInput
            id="first_name"
            :errorType="
              validations.first_name || validations.first_name_length
                ? 'danger'
                : ''
            "
            class="mb-3 w-full"
            customClass="h-[56px] bg-[#F4F6FA]"
            type="text"
            label="First Name"
            placeholder="e.g Clark"
            :readonly="getTeam.id != ''"
            v-model="getTeam.first_name"
          >
            <template v-slot:error_message>
              <InputFieldMessage
                v-if="validations.first_name"
                :message="messages.first_name"
              ></InputFieldMessage>
              <InputFieldMessage
                v-else-if="validations.first_name_length"
                :message="messages.first_name_length"
              ></InputFieldMessage>
            </template>
          </FloatingLabelInput>

          <FloatingLabelInput
            id="last_name"
            class="mb-3 w-full"
            customClass="h-[56px] bg-[#F4F6FA]"
            type="text"
            v-model="getTeam.last_name"
            placeholder="e.g Frederick"
            :errorType="
              validations.last_name || validations.last_name_length
                ? 'danger'
                : ''
            "
            label="Last Name"
            :readonly="getTeam.id != ''"
          >
            <template v-slot:error_message>
              <InputFieldMessage
                v-if="validations.last_name"
                :message="messages.last_name"
              ></InputFieldMessage>
              <InputFieldMessage
                v-else-if="validations.last_name_length"
                :message="messages.last_name_length"
              ></InputFieldMessage>
            </template>
          </FloatingLabelInput>

          <FloatingLabelInput
            id="email"
            class="mb-3 w-full"
            customClass="h-[56px] bg-[#F4F6FA]"
            type="text"
            label="Email Address"
            :errorType="
              validations.email || validations.email_length ? 'danger' : ''
            "
            v-model="getTeam.email"
            placeholder="e.g clark.frederick@mywebsite.com"
            :readonly="getTeam.id != ''"
          >
            <template v-slot:error_message>
              <InputFieldMessage
                v-if="validations.email"
                :message="messages.email"
              ></InputFieldMessage>
              <InputFieldMessage
                v-else-if="validations.email_length"
                :message="messages.email_length"
              ></InputFieldMessage>
            </template>
          </FloatingLabelInput>
          <b-dropdown
              id="roles"
              ref="roles_types_dropdown_menu"
              right
              class="w-full dropdown-menu-right hide_dropdown_caret  default_style_dropdown"
              :no-caret="true"
          >
            <div
                class=" justify-between px-[0.9rem] h-[56px] bg-[#F4F6FA] rounded-[8px] items-center gap-2.5 flex !w-full"
                slot="button-content">

              <div class="w-full flex justify-between items-center">
                <div>
                  <p class="text-[14px] text font-normal font-poppins">{{getTeam.role}}</p>
                </div>
                <span class="arrow_icon ml-auto"><i class="text-[#757A8A] font-semibold fal fa-angle-down ml-3"></i></span>
              </div>
            </div>
            <transition name="fade">
              <ul class="w-full text-[#202324] py-1 text-[0.875rem] shadow-lg bg-white rounded-lg font-poppins">
                <template v-for="role in filteredRoles">
                  <li v-model:title="getTeam.role"
                      @click="getTeam.role = role.value; $refs.roles_types_dropdown_menu.hide(true)"
                      class="list_item_li !hover:bg-[#F4F6FA] !py-3"
                  >
                    <div class="flex items-center ">
                      <div class="content">
                        <div class="text">
                          {{ role.label }}
                        </div>
                      </div>
                    </div>
                  </li>
                </template>
              </ul>
            </transition>
          </b-dropdown>
        </div>
        <div
          class="flex flex-col justify-center item-center w-full border-t border-t-[#F2F3F8]"
          v-if="loadPermissions && getTeam.role !== 'admin'"
        >
          <!-- permission child -->
          <div
            class="flex flex-col justify-start w-full"
            v-if="getTeam.role === ROLES.collaborator"
          >
            <div class="border-b border-b-[#F2F3F8] my-[1.71rem]">
            </div>

            <div
              class="flex flex-row justify-between items-center w-full px-8 py-3 bg-[#F4F6FA]"
            >
              <p
                class="flex-1 text-[#3C4549] text-[0.875] font-poppins leading-none"
              >
                Permissions
              </p>
            </div>

            <div
              class="flex flex-row justify-between items-center w-full px-8 py-3 border-t border-t-[#F2F3F8]"
            >
              <p
                class="flex-1 text-[#757A8A] text-[0.875] font-poppins leading-none"
              >
                Allow adding new brands
              </p>
              <Checkbox
                id="canCreateBrands"
                type="checkbox"
                v-model="getTeam.permission.createBrand"
                labelClass="text-[#757A8A]"
                :isDisabled="false"
              ></Checkbox>
            </div>
            <div
              class="flex flex-row justify-between items-center w-full px-8 py-3 border-t border-t-[#F2F3F8]"
            >
              <p
                class="flex-1 text-[#757A8A] text-[0.875] font-poppins leading-none"
              >
                Allow to edit and delete assigned Workspace
              </p>
              <Checkbox
                id="canEditBrands"
                type="checkbox"
                v-model="getTeam.permission.editBrand"
                labelClass="text-[#757A8A]"
                :isDisabled="false"
              ></Checkbox>
            </div>

            <div
              class="flex flex-row justify-between items-center w-full px-8 py-3 border-t border-t-[#F2F3F8]"
            >
              <p
                class="flex-1 text-[#757A8A] text-[0.875] font-poppins leading-none"
              >
                Allow viewing all Bio Links against selected workspace
              </p>
              <Checkbox
                id="canViewAllBioLinks"
                type="checkbox"
                v-model="getTeam.permission.viewAllBioLinks"
                labelClass="text-[#757A8A]"
                :isDisabled="false"
              ></Checkbox>
            </div>
          </div>
          <!-- Brands child -->
          <div
            class="flex flex-col justify-start w-full"
            v-if="[ROLES.collaborator, ROLES.guest].includes(getTeam.role)"
          >
            <div class="border-b border-b-[#F2F3F8] my-[1.71rem]">
            </div>

            <div
              class="flex flex-row justify-between items-center w-full px-8 py-3 bg-[#F4F6FA]"
            >
              <p
                class="flex-1 text-[#3C4549] text-[0.875] font-poppins leading-none"
              >
                Workspaces
              </p>
              <Checkbox
                v-model="selections.brands"
                id="brands_select_all"
                value="false"
                type="checkbox"
                labelClass="text-[#757A8A]"
                :isDisabled="false"
                @change="changeSelections('brands')"
              ></Checkbox>
            </div>

            <template
              v-for="brand in allBrands"
              v-if="getTeam.id !== brand.user_id"
            >
              <div
                class="flex flex-row justify-between items-center w-full px-8 py-3 border-t border-t-[#F2F3F8]"
              >
                <div class="flex flex-row justify-start profile_box_x gap-x-1">
                  <div
                    class="d_icon !min-w-[1.75rem] !min-h-[1.75rem]"
                    :style="brandImageLink(brand.avatar)"
                  ></div>
                  <p class="flex-1">
                    {{ brand.name }}
                    <span class="brand_name" v-if="brand.user_name">
                      By {{ brand.user_name.first_name }}
                      {{ brand.user_name.last_name }}
                    </span>
                  </p>
                </div>

                <Checkbox
                  v-model="getTeam.permission.brands"
                  :id="brand._id"
                  :inputValue="brand._id"
                  labelClass="text-[#757A8A]"
                  :isDisabled="false"
                ></Checkbox>
              </div>
            </template>
          </div>

          <!-- Pixels-->
          <div
            class="flex flex-col justify-start w-full"
            v-if="
              getTeam.role === ROLES.collaborator &&
              checkTeamLimits(getPixelList, getTeam.id)
            "
          >
            <div
              class="flex flex-row justify-between items-center w-full px-8 py-3 bg-[#F4F6FA]"
            >
              <p
                class="flex-1 text-[#3C4549] text-[0.875] font-poppins leading-none"
              >
                Pixels
              </p>
              <Checkbox
                @change="changeSelections('pixels')"
                v-model="selections.pixels"
                id="pixels_select_all"
                inputValue="false"
                type="checkbox"
                labelClass="text-[#757A8A]"
                :isDisabled="false"
              ></Checkbox>
            </div>

            <template
              v-for="pixel in getPixelList"
              v-if="getTeam.id != pixel.user_id"
            >
              <div
                class="flex flex-row justify-between items-center w-full px-8 py-3 border-t border-t-[#F2F3F8]"
              >
                <div
                  class="flex flex-row justify-start profile_box_x gap-x-1 ocial_icons_square align_center"
                >
                  <span
                    class="icon pixel_icon"
                    :class="getPixelSocialClass(pixel.type, 1)"
                  >
                    <i :class="getPixelSocialClass(pixel.type, 2)"></i>
                  </span>
                  <p class="flex-1">
                    <span class="text"
                    >{{ pixel.name }}
                      <span>{{
                          getGooglePixelOnKey(
                            pixel.type,
                            pixel.key,
                            googlePixelsTypeKey
                          )
                        }}</span>
                      <span class="brand_name" v-if="pixel.user_name">
                        By {{ pixel.user_name.first_name }}
                        {{ pixel.user_name.last_name }}
                      </span>
                    </span>
                  </p>
                </div>

                <Checkbox
                  :id="pixel._id"
                  v-model="getTeam.permission.pixels"
                  :inputValue="pixel._id"
                  labelClass="text-[#757A8A]"
                  :isDisabled="false"
                ></Checkbox>
              </div>
            </template>
          </div>

          <!-- Domains -->
          <div
            class="flex flex-col justify-start w-full"
            v-if="
              getTeam.role === ROLES.collaborator &&
              checkTeamLimits(getDomains.items, getTeam.id)
            "
          >
            <div
              class="flex flex-row justify-between items-center w-full px-8 py-3 bg-[#F4F6FA]"
            >
              <p
                class="flex-1 text-[#3C4549] text-[0.875] font-poppins leading-none"
              >
                Domains
              </p>
              <Checkbox
                @change="changeSelections('domains')"
                v-model="selections.domains"
                id="domains_select_all"
                inputValue="false"
                type="checkbox"
                labelClass="text-[#757A8A]"
                :isDisabled="false"
              ></Checkbox>
            </div>

            <template
              v-for="domain in getDomains.items"
              v-if="getTeam.id !== domain.user_id"
            >
              <div
                class="flex flex-row justify-between items-center w-full px-8 py-3 border-t border-t-[#F2F3F8]"
              >
                <div class="profile_box_x clear">
                  <div class="d_content nopad-l">
                    <p class="content_heading domain_name">
                      {{ domain.url }}
                      <span class="brand_name" v-if="domain.user_name">
                        By {{ domain.user_name.first_name }}
                        {{ domain.user_name.last_name }}
                      </span>
                    </p>
                  </div>
                </div>

                <Checkbox
                  labelClass="text-[#757A8A]"
                  :isDisabled="domain._id === getWhiteLabel.custom_domain_id"
                  v-model="getTeam.permission.domains"
                  :inputValue="domain._id"
                  :id="domain._id"
                ></Checkbox>
              </div>
            </template>
          </div>

          <!-- Integrations -->
          <div
            class="flex flex-col justify-start w-full"
            v-if="
              getTeam.role === ROLES.collaborator &&
              checkTeamLimits(getDomains.items, getTeam.id)
            "
          >
            <div
              class="flex flex-row justify-between items-center w-full px-8 py-3 bg-[#F4F6FA]"
            >
              <p
                class="flex-1 text-[#3C4549] text-[0.875] font-poppins leading-none"
              >
                Integrations
              </p>
            </div>

            <template>
              <div
                v-for="(item, index) in integrations"
                :key="index"
                v-if="getProfile[item.key] && getProfile[item.key].length > 0"
                class="flex flex-row justify-between items-center w-full px-8 py-3 border-t border-t-[#F2F3F8]"
              >
                <div class="profile_box_x clear">
                  <div class="d_content nopad-l">
                    <p class="content_heading domain_name">
                      {{ domain.url }}
                      <span class="brand_name" v-if="domain.user_name">
                        By {{ domain.user_name.first_name }}
                        {{ domain.user_name.last_name }}
                      </span>
                    </p>
                  </div>
                </div>
                <Checkbox
                  v-if="item.key == 'mailchimp'"
                  :isChecked="
                    checkTeamSelection(
                      list.user_id,
                      getTeam.permission.integrations[item.key]
                    )
                  "
                  labelClass="text-[#757A8A]"
                  v-model="getTeam.permission.domains"
                  @click="TeamIntegrationPermission(list.user_id, item.key)"
                  :id="list.user_id"
                ></Checkbox>
                <Checkbox
                  v-else
                  labelClass="text-[#757A8A]"
                  @click="TeamIntegrationPermission(list.account_id, item.key)"
                  :isChecked="
                    checkTeamSelection(
                      list.account_id,
                      getTeam.permission.integrations[item.key]
                    )
                  "
                  :id="list.account_id"
                ></Checkbox>
              </div>
            </template>
          </div>
          <!-- UTM  -->
          <div
            class="flex flex-col justify-start w-full"
            v-if="getTeam.role === ROLES.collaborator && UTMs.length"
          >
            <div
              class="flex flex-row justify-between items-center w-full px-8 py-3 bg-[#F4F6FA]"
            >
              <p
                class="flex-1 text-[#3C4549] text-[0.875] font-poppins leading-none"
              >
                UTM
              </p>

              <b-dropdown
                offset="25, 0"
                right
                class="dropdown-menu-left hide_caret default_style_dropdown"
                ref="utm_dropdown_select"
                :no-caret="true"
              >
                <div
                  class="dropdown_header border !border-[#757A8A] font-semibold h-8 px-2.5 py-2 rounded-lg d-flex align-items-center"
                  slot="button-content"
                >
                  <span class="text">{{
                      getSelectedFilter("team", "UTMs")
                    }}</span>
                  <span class="arrow_icon ml-auto"
                  ><i
                    class="text-[#757A8A] font-semibold fal fa-angle-down ml-3"
                  ></i
                  ></span>
                </div>
                <ul
                  style="
                    max-height: 20rem;
                    overflow-x: hidden;
                    overflow-y: auto;
                  "
                  class="w-full text-[#202324] font-poppins py-1 text-[0.875rem] shadow-lg bg-white rounded-tl-lg font-poppins rounded-bl-lg rounded-br-lg"
                >
                  <li
                    class="cursor-pointer border-b group hover:rounded-tl-lg px-[1rem] py-[0.75rem] flex justify-between font-poppins items-center"
                    v-if="UTMs && UTMs.length > 0"
                  >
                    <Checkbox
                      id="utms_check_all"
                      label="All UTMs"
                      v-model="select_all_utms"
                      @click="$refs.utm_dropdown_select.hide(true)"
                      @change="utmsSelectAll(select_all_utms)"
                      labelClass="font-semibold text-[#3C4549]"
                      customClass="w-full justify-between flex-row-reverse"
                    ></Checkbox>
                  </li>

                  <template v-if="UTMs && UTMs.length">
                    <template v-for="utm in UTMs">
                      <li
                        class="cursor-pointer group hover:bg-[#F4F6FA] px-[1rem] py-[0.75rem] flex justify-between font-poppins items-center"
                      >
                        <Checkbox
                          :id="utm._id"
                          :label="limitTextLength(utm.name, 21)"
                          v-model="selectedUTMs"
                          @click="$refs.tags_dropdown_select.hide(true)"
                          :inputValue="utm._id"
                          labelClass="text-[#3C4549]"
                          customClass="w-full justify-between flex-row-reverse"
                        ></Checkbox>
                      </li>
                    </template>
                  </template>
                  <li
                    class="text text-center py-[0.75rem]"
                    v-else-if="!UTMs.length"
                  >
                    You do not have any UTMs.
                  </li>
                </ul>
              </b-dropdown>
            </div>

            <template>
              <div
                v-for="(item, index) in selectedUTMs"
                :key="index"
                v-if="selectedUTMs.length > 0"
                class="flex flex-row justify-between items-center w-full px-8 py-3 border-t border-t-[#F2F3F8]"
              >
                <p
                  class="flex-1 text-[#757A8A] text-[0.875] font-poppins leading-none"
                >
                  {{ findTheNameOfUTM(item) }}
                </p>
                <div class="h-full max-w-[1.42rem]">
                  <svg
                    style="cursor: pointer;"
                    width="11"
                    height="11"
                    viewBox="0 0 11 11"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    @click="unSelectSelectedUTMs(item)"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M10.3161 1.56305C10.609 1.27015 10.609 0.795278 10.3161 0.502385C10.0232 0.209492 9.54835 0.209492 9.25546 0.502385L5.50007 4.25777L1.74469 0.502385C1.45179 0.209492 0.976919 0.209492 0.684025 0.502385C0.391132 0.795278 0.391132 1.27015 0.684025 1.56305L4.43941 5.31843L0.684025 9.07381C0.391132 9.36671 0.391132 9.84158 0.684025 10.1345C0.976919 10.4274 1.45179 10.4274 1.74469 10.1345L5.50007 6.37909L9.25546 10.1345C9.54835 10.4274 10.0232 10.4274 10.3161 10.1345C10.609 9.84158 10.609 9.36671 10.3161 9.07381L6.56073 5.31843L10.3161 1.56305Z"
                      fill="#757A8A"
                    />
                  </svg>
                </div>
              </div>
            </template>
          </div>

          <!-- end -->
        </div>
        <div
          class="flex flex-col justify-center items-center  w-full"
          v-else-if="!loadPermissions && getTeam.role === ROLES.collaborator"
        >
          <clip-loader
            :size="'26px'"
            :color="'#1d4ed8'"
          ></clip-loader>
        </div>
        <div class="my-[2.85rem] flex gap-x-3 justify-between items-center">
          <Button
            id="login-button"
            type="button"
            class="px-[2.28rem] py-[1.06rem] !text-[14px] !font-semibold !rounded-[8px] border-[1.5px] !border-gray-700 hover:bg-[#F2F3F8]"
            buttonClass="btn-lg"
            @click="close()"
          >
            <template v-slot:label>Close</template>
          </Button>

          <Button
            id="invite-button"
            type="button"
            class="px-[2.28rem] py-[1.14rem] !text-[14px] !font-semibold text-white !rounded-[8px] bg-[#2560D7] hover:bg-[#1d4ed8]"
            buttonClass="btn-lg"
            :disabled="getUserLoader.inviteTeamMemberLoader"
            @click.prevent="validateAndInviteTeamMember()"
          >
            <template
              v-if="!getUserLoader.inviteTeamMemberLoader"
              v-slot:label
            >{{ computeButtonTitle }}
            </template
            >
            <template v-if="getUserLoader.inviteTeamMemberLoader" v-slot:loader>
              <Loader></Loader>
            </template>
          </Button>
        </div>
      </div>
    </div>
  </b-modal>
</template>
<script>
import {mapActions, mapGetters, mapMutations} from "vuex";
import {DARK_PURPLE_LOADER_COLOR, ROLES} from "@/common/constants";
import http from "@/mixins/http-lib";
import Multiselect from "vue-multiselect";
import {fetchIntegrationListURL} from "@/config/config";
import {googlePixelsTypeKey} from "@/common/attributes";
import FloatingCampaignSearchDropdownV2 from "@/components/v2/FloatingCampaignSearchDropdownV2.vue";

export default {
  props: {
    selectedUTMsprop: {
      type: Array,
      default: [],
      required: false,
    },
  },
  components: {
    FloatingCampaignSearchDropdownV2,
    Multiselect,
    FloatingLabelInput: () => import("@/ui/ui-kit/v2/FloatingLabelInput.vue"),
    Selectdropdown: () => import("@/ui/ui-kit/v2/Selectdropdown.vue"),
    Button: () => import("@/ui/ui-kit/v2/Button.vue"),
    Loader: () => import("@/ui/ui-kit/v2/Loader.vue"),
    Checkbox: () => import("@/ui/ui-kit/v2/Checkbox.vue"),
    InputFieldMessage: () => import("@/ui/ui-kit/v2/InputFieldMessage.vue"),
  },
  data() {
    return {
      size: "17px",
      color: DARK_PURPLE_LOADER_COLOR,
      selectedUTMs: [],
      select_all_utms: false,
      validations: {
        first_name: false,
        first_name_length: false,
        last_name: false,
        last_name_length: false,
        email: false,
        email_length: false,
      },
      messages: {
        first_name: "First name is required.",
        first_name_length:
          "The first name should not be greater than 100 characters",
        last_name: "Last name is required.",
        last_name_length: "The last name should not be greater than 100 characters",
        email: "Email address is required.",
        email_length: "The email should not be greater than 100 characters",
      },
      loadPermissions: false,
      integrations: [],
      ROLES: ROLES,
      allBrands: [],
      UTMs: [],
      googlePixelsTypeKey: googlePixelsTypeKey,
      selections: {
        brands: true,
        pixels: true,
        domains: true,
      },
    };
  },
  async created() {
    this.allBrands = await this.fetchBrandList({page: 1, isFetchAll: true});

    // component level loader implementation
    this.UTMs = await this.fetchAllUTMs();
    this.UTMs = this.UTMs.map((u) => ({
      _id: u._id,
      name: `${u.campaign} - ${u.medium}`,
    }));

    await this.fetchIntegrationList();
    await this.preSelectOptions();
    await this.setPreSelections();
  },
  mounted() {
    setTimeout(() => {
      this.loadPermissions = true;
    }, 1000);
  },

  methods: {
    ...mapActions(["fetchBrandList", "fetchAllUTMs"]),
    ...mapMutations(["SET_TEAM_ADD_UTM_PERMISSION"]),
    /**
     * Method is responsible to fetch a list of integrations. All meta data related to the integrations to display it in integrations section
     */
    async fetchIntegrationList() {
      return http.post(fetchIntegrationListURL, {brand_id: this.getWorkspace._id}).then(
        (res) => {
          if (res.data.status) {
            this.integrations = res.data.data.map((item) => {
              return {
                key: item.key,
                logo: item.logo,
                name: item.value,
              };
            });
          }
          return true;
        },
        (err) => {
          this.alertMessage(err.message, "error");
          this.integrations = [];
        }
      );
    },
    /**
     * This method is responsible for if all the selections selected or not
     */
    setPreSelections() {
      this.selections = {
        brands: this.getTeam.permission.brands.length == this.allBrands.length,
        pixels: this.getTeam.permission.pixels.length == this.getPixelList.length,
        domains: this.getTeam.permission.domains.length == this.getDomains.items.length
      }
    },
    /**
     * This method is responsible for to get the name of selected utm based on the key provided
     * @param id
     */
    findTheNameOfUTM(id) {
      let result = null;
      for (const item of this.UTMs) {
        if (item._id === id) {
          result = item.name;
          break;
        }

      }
      return result;
    },
    /**
     * This method is responsible for to unselect a selceted UTM
     * @param id
     */
    unSelectSelectedUTMs(id) {
      console.log(id)
      this.selectedUTMs = this.selectedUTMs.filter(item => item !== id);
    },
    /**
     * This method is select all the utms
     * @param id
     */
    utmsSelectAll(event) {
      if (event) {
        this.selectedUTMs = this.UTMs.map(item => item._id)
      } else {
        this.selectedUTMs = [];
      }
    },
    /**
     * This method is responsible for selecting or deselecting all the permissions
     * @param key
     */
    changeSelections(key = "") {
      if (key === "brands")
        this.getTeam.permission.brands = this.selections.brands
          ? this.allBrands.map((brand) => brand._id)
          : [];
      if (key === "pixels")
        this.getTeam.permission.pixels = this.selections.pixels
          ? this.getPixelList.map((brand) => brand._id)
          : [];
      if (key === "domains") {
        let domains = this.getDomains.items.filter(
          (item) => item._id !== this.getWhiteLabel.custom_domain_id
        );
        let whitelabelDomainID = this.getDomains.items.find(
          (item) => item._id === this.getWhiteLabel.custom_domain_id
        );
        this.getTeam.permission.domains = this.selections.domains
          ? domains.map((brand) => brand._id)
          : [];
        this.getTeam.permission.domains.push(whitelabelDomainID._id);
      }
    },
    /**
     * In case of adding team member
     * Preselect all options (brands, pixels, domains) for collaborator and guest
     */
    preSelectOptions() {
      // it needs to auto-select for add mode only, not for edit-mode
      if (this.getTeam && this.getTeam.id) {
        // check if the utms exist
        if (this.getTeam.permission.utms) {
          const utm = this.UTMs.filter((u) =>
            this.getTeam.permission.utms.includes(u._id)
          );
          this.getTeam.permission.utms = utm;
        }
        return;
      }
      if ([ROLES.collaborator, ROLES.guest].includes(this.getTeam.role)) {
        if (this.getTeam.role === ROLES.collaborator) {
          this.getTeam.permission.createBrand = true;
          this.getTeam.permission.editBrand = true;
          this.getTeam.permission.viewAllBioLinks = true;

          // pixels
          this.getTeam.permission.pixels = this.getPixelList.map(
            (pixel) => pixel._id
          );

          // domains
          this.getTeam.permission.domains = this.getDomains.items.map(
            (domain) => domain._id
          );

          // integrations
          if (this.getIntegrationCount() > 0) {
            /**
             * Checking integrations
             */
            this.integrations.forEach((object) => {
              const integration = object.key;
              // integration exists
              if (
                this.getProfile[integration] &&
                this.getProfile[integration].length
              ) {
                // iterate on connected accounts of that integration
                this.getProfile[integration].forEach(
                  (integrationObject, index) => {
                    // Previously we were saving 'name' key in permission integrations for constantcontact.
                    // Now we would be saving account_id in case of constantcontact as well
                    const accountId =
                      integration === "mailchimp"
                        ? integrationObject.user_id
                        : integrationObject.account_id;

                    // mark as selected if it is not already selected
                    if (
                      !this.checkTeamSelection(
                        accountId,
                        this.getTeam.permission.integrations[integration]
                      )
                    ) {
                      this.TeamIntegrationPermission(accountId, integration);
                    }
                  }
                );
              }
            });
          }
        }
        // brands
        this.getTeam.permission.brands = this.allBrands.map(
          (brand) => brand._id
        );
      }
    },
    async validateAndInviteTeamMember() {
      this.validations.first_name = this.requiredCheck(this.getTeam.first_name);
      this.validations.last_name = this.requiredCheck(this.getTeam.last_name);
      this.validations.email = this.requiredCheck(this.getTeam.email);
      let result = Object.keys(this.validations).every(
        (k) => this.validations[k] === false
      );
      if (!result) {
        document.getElementById("modalAddTeam").scrollTop = 0;
        return;
      }
      /* syncing the current selected utms with vuex */
      // getTeam.permission.utms
      this.SET_TEAM_ADD_UTM_PERMISSION(this.selectedUTMs);

      let res = await this.$store.dispatch("inviteMember");
      if (!res) return;
      if (!res.data.status) {
        this.$store.dispatch("toastNotification", {
          message: res.data.message,
          type: "error",
        });
        return;
      }
      this.$store.dispatch("toastNotification", {message: res.data.message});
      if (this.getTeam.id) {
        this.getTeamMember.item.splice(
          this.getTeamMember.item.findIndex(
            (item) => item._id === this.getTeam.id
          ),
          1,
          res.data.data
        );
      } else {
        this.getTeamMember.item.push(res.data.data);
      }

      this.close();
    },
    close() {
      this.$parent.closeAddTeamModal();
    },
  },
  computed: {
    ...mapGetters([
      'getWorkspace',
      "getUserLoader",
      "getTeam",
      "getProfile",
      "getPixelList",
      "getDomains",
      "getTeamMember",
      "getWhiteLabel",
    ]),
    filteredRoles() {
      return Object.keys(ROLES)
        .filter((role) => role !== "super_admin")
        .map((role) => ({value: role, label: role}));
    },
    computeButtonTitle() {
      return this.getTeam.id ? "Update" : "Invite";
    },
  },
  watch: {
    selectedUTMsprop: {
      handler(newVal) {
        // Update the data property when the prop changes
        this.selectedUTMs = newVal;
      },
      immediate: true // This ensures that the data is initially set to the prop value
    },
    "getTeam.first_name"(value) {
      if (value && value.length > 0) {
        this.validations.first_name = false;
        this.validations.first_name_length = !this.maxLength(value, 100);
      }
    },
    "getTeam.last_name"(value) {
      if (value && value.length > 0) {
        this.validations.last_name = false;
        this.validations.last_name_length = !this.maxLength(value, 100);
      }
    },
    "getTeam.email"(value) {
      if (value && value.length > 0) {
        this.validations.email = false;
        this.validations.email_length = !this.maxLength(value, 100);
      }
    },
    "getTeam.role"(value) {
      if (value && value.length) {
        this.preSelectOptions();
      }
    },
    "getTeam.permission.brands"(value) {
      this.setPreSelections();
    },
    "getTeam.permission.pixels"(value) {
      this.setPreSelections();
    },
    "getTeam.permission.domains"(value) {
      this.setPreSelections();
    },
    selectedUTMs(value) {
      if (value.length > 0) {
        this.select_all_utms = this.UTMs.length === this.selectedUTMs.length;
      } else {
        this.select_all_utms = false;
      }
    },
  },
};
</script>

<style lang="less">
.add-edit-team-modal {
  .modal-dialog {
    max-width: 41.71rem !important;
    min-height: 39.92rem !important;
  }

  .modal-content {
    border-radius: 16px !important;
    border-color: #f2f3f8 !important;
    padding: 16px !important;
  }

  .modal-body {
    padding: 0 !important;
  }
}
</style>
