<template>
  <table class="team_table text-left flex-1 h-full">
    <thead class="bg-[#F4F6FA] text-[#757A8A] font-semibold text-[0.875rem] font-poppins">
      <tr>
        <td class="py-3 w-72 pl-3">Name</td>
        <td class="py-3 w-96 text-left pl-10">Email Address</td>
        <td class="py-3 w-60 text-left">Role</td>
        <td class="py-3 text-left">Joining Date</td>
        <td class="py-3 pr-3 text-right">More</td>
      </tr>
    </thead>
    <tbody v-if="getTeamMember.loader">
      <tr>
        <td colspan="5">
          <p style="padding: 10px">
            <clip-loader :size="'26px'" :color="'#1d4ed8'"></clip-loader>
          </p>
        </td>
      </tr>
    </tbody>
    <tbody v-else-if="getTeamMember.item.length === 0">
      <tr>
        <td colspan="5" class="">
          <p class="no-rows text-[#3C4549] text-[0.875rem] font-poppins">
            <i class="fab fa-bandcamp" aria-hidden="true"></i>
            You do not have any team member added yet.
          </p>
        </td>
      </tr>
    </tbody>
    <tbody v-else class="font-poppins text-[#3C4549] text-[0.875rem]">
      <tr v-for="member in getTeamMember.item" class="border-b border-[#ECEEF5] px-3">
        <td class="py-4 w-72 pl-3">
          <div class="profile_box_x clear">
            <div
              class="d_icon"
              :style="{ background: 'url(' + backgroundImage(member) + ')' }"
            ></div>
            <div class="d_content">
              <p class="content_heading">{{ member.full_name }}</p>
            </div>
          </div>
        </td>
        <td class="py-4 w-96 pl-10">
          {{ member.email }}
        </td>
        <td class="py-4 w-60">
          <p v-if="member.role == 'super_admin'" style="width: fit-content" class="bg-[#FFFEF9] border text-[#F4B740] p-1 !border-[#F4B740] rounded-md">Super Admin</p>
          <p v-if="member.role == 'guest'" style="width: fit-content" class="bg-[#b36ced1a] border text-[#b36ced] p-1 !border-[#b36ced] rounded-md">Guest</p>
          <p v-if="member.role == 'admin'" style="width: fit-content" class="bg-[#3a67c81a] border text-[#3a67c8] p-1 !border-[#3a67c8] rounded-md">Admin</p>
          <p v-if="member.role == 'collaborator'" style="width: fit-content" class="bg-[#b36ced1a] border text-[#b36ced] p-1 !border-[#b36ced] rounded-md">Collaborator</p>
        </td>
        <td class="py-4 ">
          {{ member.created_at | relativeCaptilize }}
        </td>

        <td class="py-4 pr-3 text-right">
          <div class="float-right" v-if="getProfile.policy.can_modify_team_members">
            <b-dropdown
              right
              ref="workspace_settings_dropdown"
              class="dropdown-menu-right hide_dropdown_caret default_style_dropdown"
              no-caret
            >
              <div
                class="dropdown_header justify-between hover:bg-[#EAF1FF] hover:text-[#2560D7] p-[0.5rem] rounded-full items-center gap-2.5 flex"
                slot="button-content"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                >
                  <circle
                    cx="10.0002"
                    cy="9.99935"
                    r="1.66667"
                    transform="rotate(-90 10.0002 9.99935)"
                    fill="#3C4549"
                  />
                  <circle
                    cx="3.33317"
                    cy="9.99935"
                    r="1.66667"
                    transform="rotate(-90 3.33317 9.99935)"
                    fill="#3C4549"
                  />
                  <circle
                    cx="16.6667"
                    cy="9.99935"
                    r="1.66667"
                    transform="rotate(-90 16.6667 9.99935)"
                    fill="#3C4549"
                  />
                </svg>
              </div>
              <transition name="fade">
                <ul
                  class="w-full text-[#202324] font-poppins py-1 text-[0.875rem] shadow-lg bg-white rounded-tl-lg font-poppins rounded-bl-lg rounded-br-lg"
                >
                  <div
                    v-if="$parent.invitationLink(member)"
                    v-clipboard:copy="$parent.invitationLink(member)"
                    v-clipboard:success="copyInvitationLinkSuccess"
                    v-clipboard:error="copyInvitationLinkError"
                    class="cursor-pointer group hover:bg-[#F4F6FA] px-[1rem] py-[0.75rem] flex gap-x-3 justify-start font-poppins items-center"
                  >
                    <svg
                      width="18"
                      height="18"
                      viewBox="0 0 18 18"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M2.185 2.185C2.35293 2.01707 2.58069 1.92273 2.81818 1.92273H9.77273C10.0102 1.92273 10.238 2.01707 10.4059 2.185C10.5738 2.35293 10.6682 2.58069 10.6682 2.81818V3.59091C10.6682 3.94989 10.9592 4.24091 11.3182 4.24091C11.6772 4.24091 11.9682 3.94989 11.9682 3.59091V2.81818C11.9682 2.23591 11.7369 1.67749 11.3251 1.26576C10.9134 0.854033 10.355 0.622727 9.77273 0.622727H2.81818C2.23591 0.622727 1.67749 0.854033 1.26576 1.26576C0.854033 1.67749 0.622727 2.23591 0.622727 2.81818V9.77273C0.622727 10.355 0.854033 10.9134 1.26576 11.3251C1.67749 11.7369 2.23591 11.9682 2.81818 11.9682H3.59091C3.94989 11.9682 4.24091 11.6772 4.24091 11.3182C4.24091 10.9592 3.94989 10.6682 3.59091 10.6682H2.81818C2.58069 10.6682 2.35293 10.5738 2.185 10.4059C2.01707 10.238 1.92273 10.0102 1.92273 9.77273V2.81818C1.92273 2.58069 2.01707 2.35293 2.185 2.185ZM7.33182 8.22727C7.33182 7.73273 7.73273 7.33182 8.22727 7.33182H15.1818C15.6764 7.33182 16.0773 7.73273 16.0773 8.22727V15.1818C16.0773 15.6764 15.6764 16.0773 15.1818 16.0773H8.22727C7.73273 16.0773 7.33182 15.6764 7.33182 15.1818V8.22727ZM8.22727 6.03182C7.01476 6.03182 6.03182 7.01476 6.03182 8.22727V15.1818C6.03182 16.3943 7.01476 17.3773 8.22727 17.3773H15.1818C16.3943 17.3773 17.3773 16.3943 17.3773 15.1818V8.22727C17.3773 7.01476 16.3943 6.03182 15.1818 6.03182H8.22727Z"
                        fill="#3C4549"
                      />
                    </svg>
                    <p>Copy Invitation link</p>
                  </div>

                  <div
                    @click.prevent="
                      $store.dispatch('resendInvitation', member._id)
                    "
                    class="cursor-pointer group hover:bg-[#F4F6FA]  px-[1rem] py-[0.75rem] flex gap-x-3 justify-start font-poppins items-center"
                  >
                    <svg
                      width="16"
                      height="14"
                      viewBox="0 0 16 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M15.3353 1.66525V5.66631H11.3342"
                        stroke="black"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M0.664734 12.3347V8.33368H4.66579"
                        stroke="black"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M2.33851 4.99947C2.67671 4.04375 3.2515 3.18927 4.00926 2.51577C4.76701 1.84227 5.68303 1.3717 6.67184 1.14797C7.66064 0.924237 8.69001 0.954638 9.66389 1.23633C10.6378 1.51803 11.5244 2.04184 12.2411 2.75889L15.3353 5.66632M0.664734 8.33368L3.75888 11.2411C4.47557 11.9582 5.36222 12.482 6.3361 12.7637C7.30998 13.0454 8.33935 13.0758 9.32815 12.852C10.317 12.6283 11.233 12.1577 11.9907 11.4842C12.7485 10.8107 13.3233 9.95625 13.6615 9.00053"
                        stroke="black"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                    <p>Resend Invitation</p>
                  </div>

                  <div
                    v-if="member.role !== ROLES.super_admin"
                    @click.prevent="$parent.editTeamMemberModal(member)"
                    class="cursor-pointer group hover:bg-[#F4F6FA] px-[1rem] py-[0.75rem] flex gap-x-3 justify-start font-poppins items-center"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="w-4 h-4"
                      viewBox="0 0 16 16"
                      fill="none"
                    >
                      <path
                        d="M11.2271 1.95798C11.6004 1.58471 12.1067 1.375 12.6346 1.375C13.1625 1.375 13.6687 1.58471 14.042 1.95798C14.4153 2.33126 14.625 2.83753 14.625 3.36543C14.625 3.89332 14.4153 4.3996 14.042 4.77288L5.12819 13.6867L1.375 14.625L2.3133 10.8718L11.2271 1.95798Z"
                        stroke="#3C4549"
                        stroke-width="1.24219"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                    <p>Edit</p>
                  </div>

                  <div
                    v-if="member.role !== ROLES.super_admin"
                    @click.prevent="confirmAction(member._id,'remove_team_member',member.status)"
                    class="cursor-pointer group hover:bg-[#F4F6FA] px-[1rem] py-[0.75rem] flex gap-x-3 justify-start font-poppins items-center"
                  >
                    <svg
                      width="16"
                      height="18"
                      viewBox="0 0 16 18"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M5.63083 1.72173C5.82519 1.52737 6.08879 1.41819 6.36365 1.41819H9.63638C9.91124 1.41819 10.1748 1.52737 10.3692 1.72173C10.5636 1.91609 10.6727 2.17969 10.6727 2.45455V3.49091H5.32729V2.45455C5.32729 2.17969 5.43647 1.91609 5.63083 1.72173ZM4.12729 3.49091V2.45455C4.12729 1.86143 4.3629 1.2926 4.7823 0.873201C5.2017 0.453802 5.77053 0.218185 6.36365 0.218185H9.63638C10.2295 0.218185 10.7983 0.453802 11.2177 0.873201C11.6371 1.2926 11.8727 1.86143 11.8727 2.45455V3.49091H13.7273H15.3636C15.695 3.49091 15.9636 3.75954 15.9636 4.09091C15.9636 4.42228 15.695 4.69091 15.3636 4.69091H14.3273V15.5455C14.3273 16.1386 14.0917 16.7074 13.6723 17.1268C13.2529 17.5462 12.684 17.7818 12.0909 17.7818H3.9091C3.31598 17.7818 2.74716 17.5462 2.32776 17.1268C1.90836 16.7074 1.67274 16.1386 1.67274 15.5455V4.69091H0.636377C0.305006 4.69091 0.036377 4.42228 0.036377 4.09091C0.036377 3.75954 0.305006 3.49091 0.636377 3.49091H2.27274H4.12729ZM2.87274 4.69091H4.72729H11.2727H13.1273V15.5455C13.1273 15.8203 13.0181 16.0839 12.8237 16.2783C12.6294 16.4726 12.3658 16.5818 12.0909 16.5818H3.9091C3.63424 16.5818 3.37064 16.4726 3.17628 16.2783C2.98193 16.0839 2.87274 15.8203 2.87274 15.5455V4.69091ZM6.36365 7.58182C6.69502 7.58182 6.96365 7.85045 6.96365 8.18182V13.0909C6.96365 13.4223 6.69502 13.6909 6.36365 13.6909C6.03228 13.6909 5.76365 13.4223 5.76365 13.0909V8.18182C5.76365 7.85045 6.03228 7.58182 6.36365 7.58182ZM10.2364 8.18182C10.2364 7.85045 9.96775 7.58182 9.63638 7.58182C9.30501 7.58182 9.03638 7.85045 9.03638 8.18182V13.0909C9.03638 13.4223 9.30501 13.6909 9.63638 13.6909C9.96775 13.6909 10.2364 13.4223 10.2364 13.0909V8.18182Z"
                        fill="#3C4549"
                      />
                    </svg>
                    <p>Remove</p>
                  </div>
                </ul>
              </transition>
            </b-dropdown>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import { DARK_PURPLE_LOADER_COLOR } from "@/common/constants";
import { ROLES } from "@/common/constants";

export default {
  name: "TeamsTable",
  data() {
    return {
      size: "17px",
      color: DARK_PURPLE_LOADER_COLOR,
      ROLES: ROLES,
    };
  },

  computed: {
    ...mapGetters(["getTeamMember"]),
    isDashboard() {
      return this.$route.name === "dashboard";
    },
  },
  methods: {
    checkdata() {
      console.log(this.getTeamMember);
    },
  },
};
</script>

<style lang="less">
.team_table {
  tbody {
    display: block;
    overflow-y: auto;
    height: calc(100vh - 15.2rem);
    table-layout: fixed;
  }
  thead,
  thead tr,
  tbody tr {
    display: table;
    width: 100%;
    table-layout: fixed;
  }
}
.my_inner_dropdown {
  .dropdown-menu {
    left: -10px !important;
  }
}
</style>
